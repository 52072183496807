import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { HIDE_BANNER } from '@bootstrap/components/banner/Banner';
import { BaseTemplate } from '@bootstrap/components/base-template';
import { LocaleSwitcher } from '@bootstrap/components/locale-switcher';
import { UserProfile } from '@bootstrap/components/UserProfile';
import { useMobile } from '@bootstrap/hooks/useMedia';
import { useCompany } from '@debtor-portal/modules/company/Company.context';
import { ReactComponent as Logout } from '@ui/assets/icons/logout.svg';
import { ReactComponent as Settings } from '@ui/assets/icons/settings.svg';
import { Button } from '@ui/button';
import { ContextMenu, ContextMenuItem } from '@ui/context-menu';
import { Divider } from '@ui/divider';
import { Popover } from '@ui/popover';
import { Sheet } from '@ui/sheet';

import { useRoutes } from './routes';

interface IMainTemplateProps {
  children: React.ReactNode;
  desktopLogo: string;
  mobileLogo: string;
  isDefaultLogo: boolean;
}

export const MainTemplate = (props: IMainTemplateProps) => {
  const isMobile = useMobile();
  const routes = useRoutes();
  const { formatMessage } = useIntl();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isUserProfileOpen, setIsUserProfileOpen] = useState(false);
  const { officialName, contactInfo } = useCompany();
  const userName = contactInfo?.name || formatMessage({ id: 'field.debtor' });

  const onCloseUserProfile = () => {
    setIsUserProfileOpen(false);
    setIsMobileMenuOpen(false);
  };

  return (
    <BaseTemplate
      isMobileMenuOpen={isMobileMenuOpen}
      toggleMobileMenu={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
      isLiveChatEnabled
      routes={routes}
      {...props}
      userProfile={
        isMobile ? (
          <>
            <UserProfile
              userName={userName}
              userCompany={officialName}
              onClick={() => setIsUserProfileOpen(!isUserProfileOpen)}
            />
            <Sheet
              isOpen={isUserProfileOpen}
              onClose={() => setIsUserProfileOpen(false)}
              title={userName}
              subtitle={officialName}
            >
              <ContextMenuContent onClose={onCloseUserProfile} />
            </Sheet>
          </>
        ) : (
          <Popover
            transform={{ left: 8 }}
            onClickOutside={onCloseUserProfile}
            isOpen={isUserProfileOpen}
            positions={['top']}
            trigger={
              <UserProfile
                userName={userName}
                userCompany={officialName}
                onClick={() => setIsUserProfileOpen(!isUserProfileOpen)}
              />
            }
            content={<ContextMenuContent onClose={onCloseUserProfile} />}
          />
        )
      }
    />
  );
};

function ContextMenuContent({ onClose }: { onClose: () => void }) {
  const navigate = useNavigate();
  const isMobile = useMobile();

  return (
    <ContextMenu offset={isMobile ? 0 : 16}>
      <LocaleSwitcher onChange={() => window.location.reload()} />
      <Divider negativeMargin="-16px" />
      <ContextMenuItem
        label={<FormattedMessage id="field.profile" />}
        onClick={() => {
          navigate('/profile');
          onClose();
        }}
        iconLeft={<Settings />}
      />
      <Divider negativeMargin="-16px" />
      <Button
        variant="secondary"
        onClick={() => {
          navigate('/logout');
          localStorage.removeItem(HIDE_BANNER);
        }}
        iconLeft={<Logout />}
        fullWidth
      >
        <FormattedMessage id="action.logout" />
      </Button>
    </ContextMenu>
  );
}
